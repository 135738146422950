@font-face {
    font-family: 'Averta Std';
    src: url('AvertaStd-Semibold.eot');
    src: url('AvertaStd-Semibold.eot?#iefix') format('embedded-opentype'),
    url('AvertaStd-Semibold.woff2') format('woff2'),
    url('AvertaStd-Semibold.woff') format('woff'),
    url('AvertaStd-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std';
    src: url('AvertaStd-Bold.eot');
    src: url('AvertaStd-Bold.eot?#iefix') format('embedded-opentype'),
    url('AvertaStd-Bold.woff2') format('woff2'),
    url('AvertaStd-Bold.woff') format('woff'),
    url('AvertaStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Averta Std';
    src: url('AvertaStd-Regular.eot');
    src: url('AvertaStd-Regular.eot?#iefix') format('embedded-opentype'),
    url('AvertaStd-Regular.woff2') format('woff2'),
    url('AvertaStd-Regular.woff') format('woff'),
    url('AvertaStd-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

