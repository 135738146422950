@font-face {
  font-family: 'icomoon';
  src:  url('/assets/fonts/icomoon.eot?syiffj');
  src:  url('/assets/fonts/icomoon.eot?syiffj#iefix') format('embedded-opentype'),
  url('/assets/fonts/icomoon.ttf?syiffj') format('truetype'),
  url('/assets/fonts/icomoon.woff?syiffj') format('woff'),
  url('/assets/fonts/icomoon.svg?syiffj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-cat-parking:before {
  content: "\e904";
}
.icon-cat-tires:before {
  content: "\e900";
}
.icon-cat-oil:before {
  content: "\e901";
}
.icon-cat-car-paint:before {
  content: "\e902";
}
.icon-cat-battery:before {
  content: "\e903";
}
