// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
    font-size: 16px;

    /** primary **/
    --ion-color-primary: #00aeef;
    --ion-color-primary-rgb: 0,174,239;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #0099d2;
    --ion-color-primary-tint: #1ab6f1;

    /** secondary **/
    --ion-color-secondary: #0f1f68;
    --ion-color-secondary-rgb: 15, 31, 104;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #0e1b50;
    --ion-color-secondary-tint: #102487;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;

    /** warning **/
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d33939;
    --ion-color-danger-tint: #f25454;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** Facebook **/
    --ion-color-facebook: #3b5998;
    --ion-color-facebook-rgb: 59, 89, 152;
    --ion-color-facebook-contrast: #ffffff;
    --ion-color-facebook-contrast-rgb: 255, 255, 255;
    --ion-color-facebook-shade: #344e86;
    --ion-color-facebook-tint: #4f6aa2;

    /** PayPal **/
    --ion-color-paypal: #179bd7;
    --ion-color-paypal-rgb: 59, 89, 152;
    --ion-color-paypal-contrast: #ffffff;
    --ion-color-paypal-contrast-rgb: 255, 255, 255;
    --ion-color-paypal-shade: #179bd7;
    --ion-color-paypal-tint: #1588be;


    /** Google **/
    --ion-color-google: #DB4437;
    --ion-color-google-rgb: 219, 68, 55;
    --ion-color-google-contrast: #ffffff;
    --ion-color-google-contrast-rgb: 255, 255, 255;
    --ion-color-google-shade: #c4372b;
    --ion-color-google-tint: #ea4e41;

    /** Twitter **/
    --ion-color-twitter: #00aced;
    --ion-color-twitter-rgb: 0, 172, 237;
    --ion-color-twitter-contrast: #ffffff;
    --ion-color-twitter-contrast-rgb: 255, 255, 255;
    --ion-color-twitter-shade: #0097d1;
    --ion-color-twitter-tint: #1ab4ef;

    /** Apple **/
    --ion-color-apple: #0e0e0e;
    --ion-color-apple-rgb: 0, 172, 237;
    --ion-color-apple-contrast: #ffffff;
    --ion-color-apple-contrast-rgb: 255, 255, 255;
    --ion-color-apple-shade: #000000;
    --ion-color-apple-tint: #4a4a4a;
    
    /** Panic **/
    --ion-color-panic: #e31e23;
    --ion-color-panic-rgb: 0, 172, 237;
    --ion-color-panic-contrast: #ffffff;
    --ion-color-panic-contrast-rgb: 255, 255, 255;
    --ion-color-panic-shade: #b60d11;
    --ion-color-panic-tint: #f13e43;

    /** White **/
    --ion-color-white: #ffffff;
    --ion-color-white-rgb: 255,255,255;
    --ion-color-white-contrast: #000000;
    --ion-color-white-contrast-rgb: 0,0,0;
    --ion-color-white-shade: #e0e0e0;
    --ion-color-white-tint: #ffffff;

    /** Home **/
    --home-drawer-background: #ffffff;

    /** Categories List **/
    --categories-background-icon: #f5f5f5;
    --categories-background-active-icon: var(--ion-color-primary);
    --categories-color-icon: #6e6e6e;
    --categories-color-text: #6e6e6e;
    --categories-border-bottom: #cccccc;

    /** Company List **/
    --premium-bg-color: rgb(255, 255, 253);
    --premium-border-left: gold;
    --premium-border-bottom: #ffec84;

    /* Settings */
    --safe-area-top: 0px;
    --section-main-radius: 25px;
    --screen-bg: #f5f6f7;
    --color-dark: #111111;
    --light-bg: #ffffff;
    --txt-dark: #58595b;
    --txt-muted: #6c757d;
    --border-radius: 25px;
    --border-radius-sm: 13px;
    --menu-btn-size: 2.8rem;
    --menu-btn-radius: 0.8rem;
    --menu-btn-bg: linear-gradient(to right top, #00aeef, #00bff0, #00cee9, #00dbd9, #1ce7c4);
    --line-shadow-top: 0 3px 11px -4px black;
    --line-shadow-bottom: 0 -3px 11px -4px black;
}

.ion-color-facebook {
    --ion-color-base: var(--ion-color-facebook);
    --ion-color-base-rgb: var(--ion-color-facebook-rgb);
    --ion-color-contrast: var(--ion-color-facebook-contrast);
    --ion-color-contrast-rgb: var(--ion-color-facebook-contrast-rgb);
    --ion-color-shade: var(--ion-color-facebook-shade);
    --ion-color-tint: var(--ion-color-facebook-tint);
}

.ion-color-paypal {
    --ion-color-base: var(--ion-color-paypal);
    --ion-color-base-rgb: var(--ion-color-paypal-rgb);
    --ion-color-contrast: var(--ion-color-paypal-contrast);
    --ion-color-contrast-rgb: var(--ion-color-paypal-contrast-rgb);
    --ion-color-shade: var(--ion-color-paypal-shade);
    --ion-color-tint: var(--ion-color-paypal-tint);
}

.ion-color-google {
    --ion-color-base: var(--ion-color-google);
    --ion-color-base-rgb: var(--ion-color-google-rgb);
    --ion-color-contrast: var(--ion-color-google-contrast);
    --ion-color-contrast-rgb: var(--ion-color-google-contrast-rgb);
    --ion-color-shade: var(--ion-color-google-shade);
    --ion-color-tint: var(--ion-color-google-tint);
}

.ion-color-twitter {
    --ion-color-base: var(--ion-color-twitter);
    --ion-color-base-rgb: var(--ion-color-twitter-rgb);
    --ion-color-contrast: var(--ion-color-twitter-contrast);
    --ion-color-contrast-rgb: var(--ion-color-twitter-contrast-rgb);
    --ion-color-shade: var(--ion-color-twitter-shade);
    --ion-color-tint: var(--ion-color-twitter-tint);
}

.ion-color-apple {
    --ion-color-base: var(--ion-color-apple);
    --ion-color-base-rgb: var(--ion-color-apple-rgb);
    --ion-color-contrast: var(--ion-color-apple-contrast);
    --ion-color-contrast-rgb: var(--ion-color-apple-contrast-rgb);
    --ion-color-shade: var(--ion-color-apple-shade);
    --ion-color-tint: var(--ion-color-apple-tint);
}

.ion-color-panic {
    --ion-color-base: var(--ion-color-panic);
    --ion-color-base-rgb: var(--ion-color-panic-rgb);
    --ion-color-contrast: var(--ion-color-panic-contrast);
    --ion-color-contrast-rgb: var(--ion-color-panic-contrast-rgb);
    --ion-color-shade: var(--ion-color-panic-shade);
    --ion-color-tint: var(--ion-color-panic-tint);
}

.ion-color-white {
    --ion-color-base: var(--ion-color-white);
    --ion-color-base-rgb: var(--ion-color-white-rgb);
    --ion-color-contrast: var(--ion-color-white-contrast);
    --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
    --ion-color-shade: var(--ion-color-white-shade);
    --ion-color-tint: var(--ion-color-white-tint);
}

/**
***   DARK MODE START    ***
**/
@media (prefers-color-scheme: dark) {
    :root {
        /* Dark mode variables go here */
    }
}
body.dark {
    --ion-color-primary: #00aeef;
    //--ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;

    /** Home **/
    --home-drawer-background: #000000;

    /** Categories List **/
    --categories-background-icon: #1f1f1f;
    --categories-background-active-icon: #1e708d;
    --categories-color-icon: #959595;
    --categories-color-text: #dadada;
    --categories-border-bottom: #444444;

    /** Company List **/
    --premium-bg-color: rgba(255, 255, 255, 0.08);
    --premium-border-left: #2ac5ff;
    --premium-border-bottom: #ffffff2e;

    /** Cards **/
    --virtual-cards-background: #323232;
}

/*
 * iOS Dark Theme
 * -------------------------------------------
 */

.ios body.dark {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;
}


/*
 * Material Design Dark Theme
 * -------------------------------------------
 */

.md body.dark {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;
}
