/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* Star Rating */
@import "../node_modules/ngx-bar-rating/themes/br-default-theme";

/* ADD GARAGE BIT CATEGORIES FONT */
@import "theme/categories-font";
@import "assets/fonts/AvertaStd/stylesheet.css";

/* ADD BOOTSTRAP */
@import "theme/bootstrap_customizations";
@import "../node_modules/bootstrap/scss/bootstrap";

@import '../node_modules/bs-stepper/dist/css/bs-stepper.min.css';

/* SWIPER */
//@import '../node_modules/swiper/swiper.min.css';
//@import 'swiper/scss';
//@import 'swiper/scss/navigation';

/* Animate.css */
@import '../node_modules/animate.css/animate.min';

* {
    letter-spacing: initial;
    line-height: initial;
    outline: none;
}

html.ios, html.md {
    --ion-default-font: "Averta Std";
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

ion-content {
    --background: var(--screen-bg);
}

.cursor-pointer {cursor: pointer;}

.btn-sm-big-icon {
    ion-icon {
        font-size: 1.2rem;
        top: -0.0179rem;
        left: -0.1rem;
    }
}

.asterisk {
    color: red;
}

a > ion-icon, .icon-justify {
    position: relative;
    top: 2px;
}

.br {
    .br-unit {
        .br-unit-inner::before {
            color: #dceeff;
        }
        .br-active::before {
            color: var(--primary)!important;
        }
    }
}

.btn-circle {
    width: 38px;
    height: 38px;
    padding: 0;
}

.grayscale {
    filter: grayscale(1);
}

.box {
    background-color: var(--light-bg);
    border-radius: var(--section-main-radius);
}

.text-pre-line {
    white-space: pre-line;
}

.up-title {
    color: grey;
    font-size: 12px;
    text-transform: uppercase;
}

.text-gradient {
    background: var(--menu-btn-bg);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-dark-blue {
    color: #06a1e6;
}

.modal-fullscreen {
    --width: 85vw!important;
    --height: 85vh!important;
}

ion-modal.login-modal {
    --width: calc(100vw - 2rem);
    --height: fit-content;
    --min-width: 250px;
    --border-radius: var(--bs-alert-border-radius, 25px);
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.btn-same {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 1rem;
}

.comment-avatar {
    width: 2rem;
    height: 2rem;
}

@media only screen and (min-width: 1200px) {
    .modal-fullscreen {
        --width: 1000px!important;
    }
}

//@for $i from -10 through 10 {
//    .top-#{$i} {
//        top: $i * 1px;
//    }
//}
//
//ion-header {
//    padding-top: var(--safe-area-top) !important;
//}

.logo {
    //height: 1.7rem;
    max-width: 8.8125rem;
}

bar-rating {
    .br.br-default {
        margin: 0 !important;
    }
}

.notification-alert {
    .alert-message {
        padding: 0;
        color: inherit;
    }
}

.sale-make {
    --make-container-size: 1.8rem;

    z-index: 2;
    width: var(--make-container-size);
    height: var(--make-container-size);
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: white;
    text-align: center;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    .sale-make-img {
        width: 80%;
        height: 80%;

    }
}

.pro-label {
    font-size: 8px;
}


// Slider
.backBtn, .nextBtn {
    position: absolute;
    top: 50%;
    border-radius: 100%;
    z-index: 2;
    width: 2.1rem;
    height: 2.1rem;
    border: none;
    background-color: rgba(255,255,255,0.4);
    box-shadow: 0 0.125rem 0.25rem rgb(37 38 39 / 8%);
    transform: translateY(-50%);
    display: flex;

    ion-icon {
        color: white !important;
        font-size: 24px;
        margin: 0 0 0 5px;
        top: 4px;
        position: relative;
    }
}
.backBtn {left: 8px;}
.nextBtn {right: 8px;}

.overflow-y-auto {
    overflow-y: auto;
}

.ellipsis-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.text-ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.map-user-photo {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: solid 3px var(--ion-color-primary);
    background-color: var(--home-drawer-background);
    filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.5));

    &:after {
        content: "";
        position: absolute;
        z-index: 50;
        bottom: -9px;
        left: calc(50% - 4px);
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 8px solid var(--ion-color-primary);
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border-radius: 100%;
    }
}

.map-my-location {
    width: 30px;
    height: 30px;
}

.gm-style .gm-style-iw-c {
    background-color: var(--home-drawer-background);
}
.gm-style .gm-style-iw-t::after {
    background: var(--home-drawer-background);
}
.dark {
    .gm-style .gm-style-iw-t::after {
        box-shadow: -2px 2px 2px 0 rgba(0, 0, 0, 0.4);
    }

    .gm-style .gm-style-iw-c {
        button[title='Close'] img {
            filter: invert(1);
        }
    }
}

.fs-7 {
    font-size: 0.75rem !important;

    &.badge, .badge {
        margin-bottom: 0.8rem;
    }
}

.vehicle-user-details {
    .icon {
        width: 25px;
        height: 25px;
        line-height: 27px;
        border-radius: 100%;
        display: inline-block;
        margin-right: 1rem;
        text-align: center;
    }
}

.gm-style-iw-d {
    overflow: hidden!important;
}

.opacity {
    opacity: 0.5;
}

.text-small {
    font-size: 0.7rem;
}

.text-color {
    color: rgb(105, 105, 105);
}

.connection-alert {

    ul.transactioned-list {
        list-style: none;
        padding: 0;

        li {

            .connect-vehicle-photo {
                height: 30px;
                border-radius: 100%;
            }
        }
    }

}

body .ngx-pagination {
    display: flex;
    justify-content: center;

    li {
        position: relative;
        white-space: nowrap;
        border-radius: 100%;
        overflow: hidden;
        width: 35px;
        height: 35px;
        background-color: white;
        margin: 0 0.2rem;
        box-shadow: 0 .125rem .25rem rgba(37, 38, 39, .075);
        font-weight: bold;
        font-size: 0.85rem;
        padding: 0!important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        &.pagination-previous {
            width: 124px;
            border-radius: 20px;
        }

        &.pagination-next {
            width: 90px;
            border-radius: 20px;
        }

        &.current {
            background-color: var(--ion-color-primary);
            color: white;
        }

        a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

ul.ngx-pagination {
    padding: 0;
    margin: 0;

    li {
        //border-radius: 100%;
        //background-color: white;
        //@extend .shadow-sm;
    }
}

//.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal>.swiper-pagination-bullets {
//    bottom: 0 !important;
//}

.stories.snapgram .story.seen > .item-link > .item-preview {
    background: #999 !important;
}
.stories.snapgram .story > .item-link > .item-preview {
    background: rgb(1,121,166) !important;
    background: linear-gradient(232deg, rgba(1,121,166,1) 0%, rgba(0,156,214,1) 49%, rgba(0,174,239,1) 100%) !important;
    //background: rgb(0,167,230);
    //background: linear-gradient(90deg, rgba(0,167,230,1) 41%, rgba(113,196,227,1) 75%);
    //background: #00a7e6;
    //background: -moz-linear-gradient(45deg, #00a7e6 0%, #71c4e3 25%, #00a7e6 50%, #06688c 75%);
    //background: -webkit-linear-gradient(45deg, #00a7e6 0%, #71c4e3 25%, #044a63 50%, #06688c 75%);
    //background: linear-gradient(45deg, #00a7e6 0%, #71c4e3 25%, #044a63 50%, #06688c 75%);
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00a7e6', endColorstr='#06688c',GradientType=1 );

    //instagram
    //background: #f09433;
    //background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    //background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    //background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

#zuck_vehicle_sales > div > a > span.info > strong {
    font-size: 0.85rem;
}

@media (min-width: 1200px) {
    #zuck_vehicle_sales > div > a > span.item-preview > img {
        width: 70px;
        height: 70px;
    }
    #zuck_vehicle_sales > div > a > span.item-preview {
        max-height: 74px;
        max-width: 74px;
    }
    #zuck_vehicle_sales > div {
        max-width: 74px;
        width: auto;
        margin: 0;
    }
    #zuck_vehicle_sales > div {
        margin-right: 0.7rem;
    }
}
