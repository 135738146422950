$font-family-base: 'Averta Std';

$enable-cssgrid: true;

$border-radius-sm: 13px;
$border-radius: 25px;

$input-border-radius-sm: 2rem;
$input-border-color: transparent;
$input-bg: #eff2f4;
$input-color: #666;

$primary:       #00aeef;
$secondary:     #6C757DFF !default;
$success:       #198754FF !default;
$info:          #0DCAF0FF !default;
$warning:       #FFC107FF !default;
$danger:        #DC3545FF !default;
$light:         #F8F9FAFF !default;
$dark:          #212529FF !default;

$min-contrast-ratio: 2.5 !default;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
);

$custom-colors: (
    "white": #fff
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

.alert-sm {
    --bs-alert-padding-y: 0.35rem!important;
    --bs-alert-padding-x: 1rem!important;
    font-size: 0.875rem;
}
